import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TrashIcon from "../../../../../../assets/icons/trash.svg?react";

import ProductService from "@/services/productService";

import useAdditionalPackagesStore from "@/stores/useAdditionalPackagesStore";
import { useTranslation } from "react-i18next";

const FilterTags = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    maintenanceLinkedFilters,
    setMaintenanceLinkedFilters,
    getMaintenanceLinkedProducts,
  } = useAdditionalPackagesStore();

  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [brand, setBrand] = useState("");

  const getMainCategory = async () => {
    const { data } = await ProductService.getMainCategories();
    const mainCategory = data.find(
      (mainCategory) =>
        mainCategory.id == maintenanceLinkedFilters.mainCategoryId,
    );
    setMainCategory(mainCategory);
  };

  const getCategory = async () => {
    const { data } = await ProductService.getCategories(
      maintenanceLinkedFilters.mainCategoryId,
    );
    const category = data.find(
      (category) => category.id == maintenanceLinkedFilters.categoryId,
    );
    setCategory(category);
  };

  const getSubCategory = async () => {
    const { data } = await ProductService.getSubCategories(
      maintenanceLinkedFilters.categoryId,
    );
    const subCategory = data.find(
      (subCategory) => subCategory.id == maintenanceLinkedFilters.subCategoryId,
    );
    setSubCategory(subCategory);
  };

  const getBrand = async () => {
    const { data } = await ProductService.getSubCategoryBrands(
      maintenanceLinkedFilters.subCategoryId,
    );
    const brand = data.find(
      (brand) => brand.id == maintenanceLinkedFilters.subCategoryBrandId,
    );
    setBrand(brand);
  };

  useEffect(() => {
    maintenanceLinkedFilters.mainCategoryId && getMainCategory();
    maintenanceLinkedFilters.categoryId && getCategory();
    maintenanceLinkedFilters.subCategoryId && getSubCategory();
    maintenanceLinkedFilters.subCategoryBrandId && getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceLinkedFilters]);

  return (
    <div className="flex h-full w-full items-center justify-start gap-4">
      {maintenanceLinkedFilters.name && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {maintenanceLinkedFilters.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceLinkedFilters({
                ...maintenanceLinkedFilters,
                name: "",
              });
              getMaintenanceLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {maintenanceLinkedFilters.mainCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {mainCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceLinkedFilters({
                ...maintenanceLinkedFilters,
                mainCategoryId: "",
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {maintenanceLinkedFilters.categoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {t(category.name)}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceLinkedFilters({
                ...maintenanceLinkedFilters,
                categoryId: "",
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {maintenanceLinkedFilters.subCategoryId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {subCategory.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceLinkedFilters({
                ...maintenanceLinkedFilters,
                subCategoryId: "",
                subCategoryBrandId: "",
              });
              getMaintenanceLinkedProducts(id);
            }}
          />
        </Tag>
      )}
      {maintenanceLinkedFilters.subCategoryBrandId && (
        <Tag size="sm" className="flex items-center justify-center gap-2">
          {brand.name}
          <TrashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={() => {
              setMaintenanceLinkedFilters({
                ...maintenanceLinkedFilters,
                subCategoryBrandId: "",
              });
              getMaintenanceLinkedProducts(id);
            }}
          />
        </Tag>
      )}
    </div>
  );
};

export default FilterTags;
