import axios from "@/lib/axios";

class ExtendedWarrantyService {
  constructor() {
    this.api = axios;
  }

  async getExtendedWarramties(
    query = {
      page: 1,
      size: 10,
    },
  ) {
    const { data } = await this.api.get("/extended-warranties", {
      params: query,
    });

    return data;
  }

  async getExtendedWarranty(id) {
    const { data } = await this.api.get(`/extended-warranties/${id}`);

    return data;
  }
}

export default new ExtendedWarrantyService();
