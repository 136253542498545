import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import extendedWarrantyService from "@/services/extendedWarrantyService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const Detail = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const [page, setPage] = useState(1);
  const { id } = useParams();
  const { toast } = useToast();

  useEffect(() => {
    extendedWarrantyService.getExtendedWarranty(id).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/extended-warranties">
          {t("extendedWarrantiesPage.title")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantiesPage.detail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("extendedWarrantiesPage.detail")}
          </h2>
          {/* <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("clientsPage.name")}</Label>
            <Input value={data?.name} disabled />
          </div> */}
          {/* {
    "id": 3,
    "firstName": "Kamil",
    "lastName": "Kınacı",
    "email": "test@egaranti.com",
    "phone": "+905335970151",
    "productName": "Sorti Gümüşlük",
    "brand": "Evgör",
    "productPrice": 2500,
    "offerPrice": 89,
    "extendedWarrantyYear": 1,
    "extendedWarrantyExpireDate": "25-11-2027 00:00:00",
    "externalProductName": "1 yıl uzatılmış garanti paketi",
    "externalOrderId": 10746,
    "externalPolicyNo": "TECH-1205",
    "createdAt": "25-11-2024 15:05:13",
    "updatedAt": "25-11-2024 15:05:13"
} */}
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.firstName")}
            </Label>
            <Input value={data?.firstName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.lastName")}
            </Label>
            <Input value={data?.lastName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.email")}
            </Label>
            <Input value={data?.email} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.phone")}
            </Label>
            <Input value={data?.phone} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.productName")}
            </Label>
            <Input value={data?.productName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.brand")}
            </Label>
            <Input value={data?.brand} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.productPrice")}
            </Label>
            <Input value={data?.productPrice} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.offerPrice")}
            </Label>
            <Input value={data?.offerPrice} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.extendedWarrantyYear")}
            </Label>
            <Input value={data?.extendedWarrantyYear} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.extendedWarrantyExpireDate")}
            </Label>
            <Input value={data?.extendedWarrantyExpireDate} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">
              {t("extendedWarrantiesPage.externalProductName")}
            </Label>
            <Input value={data?.externalProductName} disabled />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Detail;
