/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import individualCustomersService from "@/services/individualCustomersService";

import * as z from "zod";

const RemindWarrantySheet = ({ selectedClientId, onOpenChange, open }) => {
  const { t } = useTranslation();
  const [selectedTypes, setSelectedTypes] = useState(["PHONE"]);
  const { toast } = useToast();

  const formSchema = z.object({
    email: z
      .string()
      .email({
        message: t("loginForm.validateMsg.emailInvalid"),
      })
      .min(2, {
        message: t("loginForm.validateMsg.emailMin"),
      })
      .max(50, {
        message: t("loginForm.validateMsg.emailMax"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async () => {
    individualCustomersService
      .remindIndividualCustomer(selectedClientId, { types: selectedTypes })
      .then((res) => {
        onOpenChange(false);
        toast({
          title: t("clientsPage.reminderSent"),
          variant: "success",
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const toggleType = (type) => {
    setSelectedTypes((current) =>
      current.includes(type)
        ? current.filter((t) => t !== type)
        : [...current, type],
    );
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("clientsPage.remindWarranty")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <p className="font-medium text-gray-500">
            {t(
              "clientsPage.remindWarrantyDesc",
              "Müşterilerinize garanti belgelerini hatırlatmak için tekrar SMS veya E-Posta gönderebilirsiniz.",
            )}
          </p>
          <Label>{t("clientsPage.reminderType")}</Label>
          <div className="flex flex-col gap-2">
            {[
              { name: t("clientsPage.phone"), value: "PHONE" },
              { name: t("clientsPage.email"), value: "EMAIL" },
            ].map((type) => (
              <div key={type.value} className="flex items-center space-x-2">
                <Checkbox
                  checked={selectedTypes.includes(type.value)}
                  onCheckedChange={() => toggleType(type.value)}
                />
                <Label>{type.name}</Label>
              </div>
            ))}
          </div>
          <SheetFooter className="mt-4">
            <Button onClick={onSubmit}>{t("common.save")}</Button>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default RemindWarrantySheet;
