import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Tabs } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";
import { FileLink } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import documentsService from "@/services/documentsService";

import AddProduct from "@/components/documents/edit/addProduct";
import LinkedProducts from "@/components/documents/edit/linkedProducts";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import * as z from "zod";

const DocumentEditPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { toast } = useToast();
  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(3, t("common.validateMsg.requiredField")),
        type: z.string().min(3, t("common.validateMsg.requiredField")),
        file: z.any().optional(),
      }),
    ),
    defaultValues: {
      name: "",
      type: "",
    },
  });

  const onSubmit = (data) => {
    const dataToSend = {
      id: id,
      ...data,
    };

    const formData = new FormData();
    formData.append("multipartFile", data.file);
    documentsService
      .updateDocument(dataToSend, formData)
      .then(() => {
        toast({
          title: t("settingsPage.documentUpdatedSuccessfully"),
          variant: "success",
        });
      })
      .catch(() => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  useEffect(() => {
    documentsService.getDocument(id).then((res) => {
      form.setValue("name", res.name);
      form.setValue("documentUrl", res.documentUrl);
      form.setValue("type", res.type);
    });
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/settings/documents">
          {t("settingsPage.documents")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("settingsPage.editDocument")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox">
          <h2 className="mb-5 font-medium">{t("settingsPage.documentInfo")}</h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid w-full grid-cols-2 gap-4"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("settingsPage.documentName")}</Label>
                    <FormControl>
                      <Input maxLength={50} placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.watch("documentUrl") ? (
                <div className="flex flex-col gap-3">
                  <Label>{t("settingsPage.document")}</Label>
                  <FileLink
                    onDeleted={() => form.setValue("documentUrl", null)}
                    name={form.watch("name")}
                    url={form.watch("documentUrl")}
                  />
                </div>
              ) : (
                <FormField
                  control={form.control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("settingsPage.document")}</Label>
                      <FormControl>
                        <Input
                          accept=".jpg, .jpeg, .png, .pdf"
                          type="file"
                          onChange={(e) => {
                            field.onChange(
                              e.target.files ? e.target.files[0] : null,
                            );
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("settingsPage.documentType")}</Label>
                    <FormControl>
                      <RadioGroup
                        className="flex py-5"
                        onValueChange={(value) => {
                          form.setValue("type", value);
                        }}
                        value={form.watch("type")}
                        {...field}
                      >
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            disabled
                            value="USER_MANUAL_DOCUMENT"
                            id="USER_MANUAL_DOCUMENT"
                          />
                          <Label htmlFor="USER_MANUAL_DOCUMENT">
                            {t("settingsPage.userManual")}
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            disabled
                            value="WARRANTY_DOCUMENT"
                            id="WARRANTY_DOCUMENT"
                          />
                          <Label htmlFor="WARRANTY_DOCUMENT">
                            {t("settingsPage.warrantyDocument")}
                          </Label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
        <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
          <div className="formBox">
            <h2 className="mb-5 font-medium">
              {t("settingsPage.selectedProducts")}
            </h2>
            <div className="border-b">
              <Tabs
                tabs={[
                  { id: 1, title: t("settingsPage.affiliatedProducts") },
                  { id: 2, title: t("settingsPage.addProduct") },
                ]}
                selectedTabId={activeTab}
                onTabChange={(id) => setActiveTab(id)}
              />
            </div>
            {activeTab === 1 && <LinkedProducts />}
            {activeTab === 2 && <AddProduct />}
          </div>
        </div>
      </div>
    </main>
  );
};

export default DocumentEditPage;
