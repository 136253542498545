/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CopyIcon from "../../../assets/icons/copy.svg?react";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import EyeOffIcon from "../../../assets/icons/eyeOff.svg?react";
import PasswordResetSheet from "./passwordResetSheet";

import authService from "@/services/authService";

import InfoTooltip from "@/components/shared/infoTooltip";

import { cn } from "@/lib/utils";

const CompanyTab = ({ merchant, getData }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [canSeeApiKey, setCanSeeApiKey] = useState(false);
  const [canSeeSecret, setCanSeeSecret] = useState(false);
  const [showSheet, setShowSheet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({
    contactEmail: merchant?.contactEmail,
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDigits = (value) => {
    if (value) {
      if (value.length > 4) {
        return "*".repeat(value.length - 4) + value.slice(-4);
      }
      return value;
    } else {
      return null;
    }
  };

  const companyFields = [
    { label: t("settingsPage.contactEmail"), value: merchant?.contactEmail },
    { label: t("settingsPage.companyName"), value: merchant?.title },
    { label: "Website", value: merchant?.website },
  ];

  const integrationFields = [
    {
      label: "API Key",
      value: merchant?.apiKey,
      link: true,
    },
    {
      label: "Secret Key",
      value: merchant?.secretKey,
      link: true,
    },
  ];

  const onSave = () => {
    setLoading(true);
    authService
      .updateMerchant(newData)
      .then(() => {
        getData();
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.errors[0]?.message,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="mb-2 flex max-w-[800px] items-center justify-between pt-5">
          <h2 className="font-medium text-[#97a2b6]">
            {t("settingsPage.companyInfo")}
          </h2>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="secondaryColor">{t("settingsPage.edit")}</Button>
            </SheetTrigger>
            <SheetContent className="m-4 rounded-lg bg-white p-4">
              <SheetHeader>
                <SheetTitle>{t("settingsPage.edit")}</SheetTitle>
              </SheetHeader>
              <div className="mt-4 flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <Label>{t("common.email")}</Label>
                  <Input
                    value={newData?.contactEmail}
                    onChange={(e) =>
                      setNewData({ ...newData, contactEmail: e.target.value })
                    }
                  />
                </div>
              </div>
              <SheetFooter className="mt-4">
                <SheetClose asChild>
                  <Button
                    disabled={loading || !newData.contactEmail}
                    onClick={onSave}
                  >
                    {t("common.save")}
                  </Button>
                </SheetClose>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>
        <div className="flex max-w-[800px] flex-col gap-4 rounded-lg border border-[#F2F5F9] bg-white p-6 shadow-sm">
          {companyFields.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 border-b pb-4 last:border-0 last:pb-0"
            >
              <label className="text-sm font-medium text-[#97a2b6]">
                {item.label}
              </label>
              <span
                className={cn(
                  "text-sm font-semibold text-[#2b2b2b]",
                  item.link && "text-[#0049E6] underline",
                )}
              >
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-4">
        <div className="flex h-[44px] max-w-[800px] items-center justify-between">
          <h2 className="flex items-center gap-2 font-medium text-[#97a2b6]">
            <span>{t("settingsPage.integrationInfo")}</span>
            <InfoTooltip>{t("settingsPage.tooltipText")}</InfoTooltip>
          </h2>
        </div>
        <div className="flex max-w-[800px] flex-col gap-4 rounded-lg border border-[#F2F5F9] bg-white p-6 shadow-sm">
          {integrationFields.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 border-b pb-4 last:border-0 last:pb-0"
            >
              <label className="text-sm font-medium text-[#97a2b6]">
                {item.label}
              </label>
              <div className="flex items-center gap-2">
                <span
                  className={cn(
                    "text-sm font-semibold text-[#111827]",
                    item.link && "text-[#0049E6] underline",
                  )}
                >
                  <span>
                    {item.label.includes("API Key")
                      ? canSeeApiKey
                        ? item.value
                        : changeDigits(item.value)
                      : null}
                    {item.label.includes("Secret")
                      ? canSeeSecret
                        ? item.value
                        : changeDigits(item.value)
                      : null}
                  </span>
                </span>
                <Icons
                  item={item}
                  canSeeApiKey={canSeeApiKey}
                  canSeeSecret={canSeeSecret}
                  setCanSeeApiKey={setCanSeeApiKey}
                  setCanSeeSecret={setCanSeeSecret}
                  toast={toast}
                />
              </div>
              {index !== integrationFields.length - 1 && (
                <span className="absolute bottom-0 h-[1px] w-[95%] bg-[#f3f4f6]"></span>
              )}
            </div>
          ))}
        </div>
        <div className="flex max-w-[800px] justify-end gap-4">
          <Button className="w-max" onClick={() => setShowSheet(true)}>
            {t("settingsPage.resetPasswordSheet.changePassword")}
          </Button>
        </div>
      </div>
      <PasswordResetSheet
        open={showSheet}
        onOpenChange={setShowSheet}
        onClose={() => setShowSheet(false)}
      />
    </>
  );
};

export default CompanyTab;

const Icons = ({
  item,
  canSeeApiKey,
  canSeeSecret,
  setCanSeeApiKey,
  setCanSeeSecret,
  toast,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="flex items-center gap-3">
        <button
          aria-label="göster"
          onClick={() => {
            item.label.includes("API Key")
              ? setCanSeeApiKey(!canSeeApiKey)
              : setCanSeeSecret(!canSeeSecret);
          }}
        >
          {item.label.includes("API Key") && canSeeApiKey && <EyeIcon />}
          {item.label.includes("API Key") && !canSeeApiKey && <EyeOffIcon />}
          {item.label.includes("Secret") && canSeeSecret && <EyeIcon />}
          {item.label.includes("Secret") && !canSeeSecret && <EyeOffIcon />}
        </button>
        {item.label.includes("API Key") && canSeeApiKey && (
          <button
            aria-label="kopyala"
            onClick={() => {
              navigator.clipboard.writeText(item.value);
              toast({
                description: `${item.label} ${t("settingsPage.copied")}`,
                variant: "success",
              });
            }}
          >
            <CopyIcon className="h-5 w-5" />
          </button>
        )}
        {item.label.includes("Secret") && canSeeSecret && (
          <button
            aria-label="kopyala"
            onClick={() => {
              navigator.clipboard.writeText(item.value);
              toast({
                description: `${item.label} ${t("settingsPage.copied")}`,
                variant: "success",
              });
            }}
          >
            <CopyIcon className="h-5 w-5" />
          </button>
        )}
      </span>
    </>
  );
};
