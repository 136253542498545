import {
  Button,
  FileLink,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  useToast,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ProductService from "@/services/productService";

import * as z from "zod";

const ImportProductSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const formSchema = z.object({
    mainCategory: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    categoryId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    subCategoryId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    subCategoryBrandId: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    file: z
      .any()
      .optional()
      .refine((file) => file !== null, {
        message: t("common.validateMsg.requiredField"),
      }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mainCategory: "",
      categoryId: "",
      subCategoryId: "",
      subCategoryBrandId: "",
      file: null,
    },
  });

  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const getMainCategories = async () => {
    const { data } = await ProductService.getMainCategories();
    setMainCategories(data);
  };

  const getCategories = async (e) => {
    const { data } = await ProductService.getCategories(e);
    setCategories(data);
  };

  const getSubCategories = async (e) => {
    const { data } = await ProductService.getSubCategories(e);
    setSubCategories(data);
  };

  const getBrands = async (e) => {
    const { data } = await ProductService.getSubCategoryBrands(e);
    setBrands(data);
  };

  useEffect(() => {
    getMainCategories();
  }, []);

  const handleMainCategoryChange = (e) => {
    getCategories(e);
  };

  const handleCategoryChange = (e) => {
    getSubCategories(e);
  };

  const handleSubCategoryChange = (e) => {
    getBrands(e);
  };

  function onSubmit(body) {
    const formData = new FormData();
    formData.append("file", body.file);
    ProductService.importProducts(body.subCategoryBrandId, formData)
      .then(() => {
        toast({
          title: t("clientsPage.importSheet.success"),
          variant: "success",
        });

        onOpenChange(false);
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response?.data?.errors[0].message,
        });
      });
  }

  useEffect(() => {
    if (open) {
      setMainCategories([]);
      setCategories([]);
      setSubCategories([]);
      setBrands([]);
      getMainCategories();
      form.reset();
    }
  }, [open]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("clientsPage.importSheet.importProduct")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4 pb-6">
          <p className="font-medium text-[#111729]">
            {t(
              "clientsPage.importSheet.desc1",
              "Ürünlerinizi içe aktarmak için excel şablonunu buradan indirip doldurabilirsiniz.",
            )}
          </p>
          <FileLink url="https://cdn.egaranti.com/static/product_import.csv" />
          <hr className="my-6" />
          <p className="font-medium text-[#111729]">
            {t(
              "clientsPage.importSheet.desc2",
              "İçe aktarmak istediğiniz ürün kategorisini seçiniz.",
            )}
          </p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-5">
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="mainCategory"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.mainCategory")} *</Label>
                        <Select
                          {...field}
                          onValueChange={(e) => {
                            field.onChange(e);
                            handleMainCategoryChange(e);
                          }}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {mainCategories.map((category, index) => (
                              <SelectItem
                                key={index}
                                value={category.id.toString()}
                              >
                                {t(category.name)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {categories.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="categoryId"
                      render={({ field }) => (
                        <FormItem>
                          <Label>{t("productsPage.category")} *</Label>
                          <Select
                            {...field}
                            onValueChange={(e) => {
                              field.onChange(e);
                              handleCategoryChange(e);
                            }}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {categories.map((category, index) => (
                                <SelectItem
                                  key={index}
                                  value={category.id.toString()}
                                >
                                  {t(category.name)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                {subCategories.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="subCategoryId"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            {...field}
                            onValueChange={(value) => {
                              field.onChange(value);
                              handleSubCategoryChange(value);
                            }}
                          >
                            <Label>{t("productsPage.subCategory")} *</Label>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {subCategories.map((category, index) => (
                                <SelectItem
                                  key={index}
                                  value={category.id.toString()}
                                >
                                  {t(category.name)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                {brands.length > 0 && (
                  <div className="flex flex-col gap-4">
                    <FormField
                      control={form.control}
                      name="subCategoryBrandId"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            {...field}
                            onValueChange={(e) => {
                              field.onChange(e);
                            }}
                            value={field.value}
                          >
                            <Label>{t("productsPage.brand")} *</Label>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {brands.map((brand, index) => (
                                <SelectItem
                                  key={index}
                                  value={brand.id.toString()}
                                >
                                  {brand.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
              </div>
              <p className="my-3 font-medium text-[#111729]">
                {t(
                  "clientsPage.importSheet.desc3",
                  "İçe aktaracağınız ürünlerin yer aldığı excel dosyasını yükleyiniz.",
                )}
              </p>
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("productsPage.category")} *</Label>
                    <FormControl>
                      <Input
                        type="file"
                        onChange={(e) => {
                          field.onChange(
                            e.target.files.length > 0
                              ? e.target.files[0]
                              : null,
                          );
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <SheetFooter className="mt-4">
            <Button
              onClick={() => {
                form.handleSubmit(onSubmit)();
              }}
            >
              {t("common.import")}
            </Button>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ImportProductSheet;
