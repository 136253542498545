/* eslint-disable react/prop-types */
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import setupClaimsService from "@/services/setupClaimsService";

// eslint-disable-next-line react/prop-types
const ConfirmAppointment = ({
  onOpenChange,
  open,
  appointmentInfo,
  reFetchData,
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onSubmit = () => {
    setupClaimsService
      .updateClaim(appointmentInfo.data.id, {
        setupClaimOptionId: appointmentInfo.pickedDate.id,
        merchantNote: appointmentInfo.data.merchantNote,
        setupClaimStatus: "APPROVED",
      })
      .then(() => {
        onOpenChange(false);
        toast({
          variant: "success",
          title: t("common.success"),
        });
        navigate("/claims/setup");
      })
      .catch((err) => {
        toast({
          variant: "error",
          title: err?.response?.data?.errors[0]?.message,
        });
      });
  };
  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent className="w-[400px]">
        <AlertDialogHeader>
          <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto"
          >
            <rect x="4" y="4" width="64" height="64" rx="32" fill="#ECFDF3" />
            <rect
              x="4"
              y="4"
              width="64"
              height="64"
              rx="32"
              stroke="#D1FADF"
              strokeWidth="8"
            />
            <path
              d="M48 33.3333H24M48 36.6667V31.7333C48 29.4931 48 28.373 47.564 27.5174C47.1805 26.7647 46.5686 26.1528 45.816 25.7693C44.9603 25.3333 43.8402 25.3333 41.6 25.3333H30.4C28.1598 25.3333 27.0397 25.3333 26.184 25.7693C25.4314 26.1528 24.8195 26.7647 24.436 27.5174C24 28.373 24 29.4931 24 31.7333V42.9333C24 45.1735 24 46.2937 24.436 47.1493C24.8195 47.902 25.4314 48.5139 26.184 48.8974C27.0397 49.3333 28.1598 49.3333 30.4 49.3333H36M41.3333 22.6667V28M30.6667 22.6667V28M39.3333 45.3333L42 48L48 42"
              stroke="#12B76A"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <AlertDialogTitle className="mx-auto w-[300px] text-center">
            {t(
              "claims.alertTitle",
              "Kurulum randevusu oluşturmak istediğinize emin misiniz?",
            )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <div className="flex flex-col">
          <div className="mx-auto w-max">
            <span>{t("common.customer", "Müşteri")}:</span>{" "}
            <span>{`${appointmentInfo?.data.individualCustomerFirstName} ${appointmentInfo?.data.individualCustomerLastName}`}</span>
          </div>
          <div className="mx-auto w-max">
            <span>{t("common.dateText", "Tarih")}: </span>{" "}
            <span>{appointmentInfo?.pickedDate.setupDate}</span>
          </div>
          <div className="mx-auto w-max">
            <span>{t("common.clockText", "Saat")}:</span>{" "}
            <span>
              {" "}
              {appointmentInfo?.pickedDate?.partOfDay === "MORNING"
                ? "08.00 - 12.00"
                : appointmentInfo?.pickedDate?.partOfDay === "AFTERNOON"
                  ? "12.00 - 16.00"
                  : "16.00 - 20.00"}
            </span>
          </div>
        </div>
        <AlertDialogFooter>
          <div className="mx-auto flex w-max gap-4">
            <AlertDialogCancel>
              {t("common.discardText", "Vazgeç")}
            </AlertDialogCancel>
            <AlertDialogAction onClick={onSubmit}>
              {t("claims.createAppointment", "Randevu Oluştur")}
            </AlertDialogAction>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmAppointment;
