import i18n from "@/i18n";
import axios from "@/lib/axios";

class DocumentsService {
  constructor() {
    this.api = axios;
  }

  async getDocuments(filters) {
    const { data } = await this.api.get("/merchant-product-documents", {
      params: filters,
    });

    return data;
  }

  async getDocument(documentId) {
    const { data } = await this.api.get(
      `/merchant-product-documents/${documentId}`,
    );

    return data;
  }

  async getDocumentLinkedProducts(documentId, filters) {
    const { data } = await this.api.get(
      `/merchant-product-documents/${documentId}/linked-products`,
      { params: filters },
    );

    return data;
  }

  async getDocumentUnlinkedProducts(documentId, filters) {
    const { data } = await this.api.get(
      `/merchant-product-documents/${documentId}/unlinked-products`,
      { params: filters },
    );

    return data;
  }

  async createDocument(data, file) {
    const response = await this.api.post(
      `/merchant-product-documents?name=${data.name}&type=${data.type}`,
      file,
    );

    return response;
  }

  async updateDocument(newData, formData) {
    const response = await this.api.put(
      `/merchant-product-documents/${newData.id}?name=${newData.name}`,
      formData,
    );

    return response;
  }

  async deleteDocument(documentId) {
    const response = await this.api.delete(
      `/merchant-product-documents/${documentId}`,
    );

    return response;
  }

  async unlinkProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/unlink-products`,
      data,
    );

    return response;
  }

  async unlinkProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/unlink-products-filter`,
      data,
    );

    return response;
  }

  async linkProducts(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/link-products`,
      data,
    );

    return response;
  }

  async linkProductsFilter(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/link-products-filter`,
      data,
    );

    return response;
  }

  async getDocumentLinkedProductsCount(documentId) {
    const { data } = await this.api.get(
      `/merchant-product-documents/${documentId}/linked-products/count`,
    );

    return data;
  }

  // /merchant-product-documents/{merchantProductDocumentId}/link-products-document
  async linkProductsDocument(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/link-products-filter`,
      data,
    );

    return response;
  }

  async unlinkProductsDocument(documentId, data) {
    const response = await this.api.put(
      `/merchant-product-documents/${documentId}/unlink-products-filter`,
      data,
    );

    return response;
  }

  // /default-warranty-document-merchant-info
  async getDefaultWarrantyDocuments() {
    const { data } = await this.api.get(
      "/default-warranty-document-merchant-info",
    );

    return data;
  }

  cleanInlineStyles = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const elementsWithStyle = tempDiv.querySelectorAll("[style]");
    elementsWithStyle.forEach((element) => {
      element.removeAttribute("style");
    });

    return tempDiv.innerHTML;
  };

  async createDefaultWarrantyDocument(data, mutlipartData) {
    data.warrantyConditions = this.cleanInlineStyles(data.warrantyConditions);
    const response = await this.api.post(
      `/default-warranty-document-merchant-info?name=${data.name}&address=${data.address}&phone=${data.phone}&fax=${data.fax}&warrantyConditions=${data.warrantyConditions}`,
      mutlipartData,
    );

    return response;
  }

  async previewDefaultWarrantyDocument() {
    const response = await this.api.get(
      `/default-warranty-document-histories/document-preview`,
    );

    if (response && response.data && response.data.documentCdnUrl) {
      const merchantInfo = encodeURIComponent(
        JSON.stringify(response.data.merchantInfo),
      );

      const previewUrl = `${response.data.documentCdnUrl}&merchantInfo=${merchantInfo}&lang=${i18n.language}`;

      window.open(previewUrl, "_blank");
    }

    return response;
  }

  async updateDefaultWarrantyDocument(data, mutlipartData) {
    data.warrantyConditions = this.cleanInlineStyles(data.warrantyConditions);
    console.log(data.warrantyConditions);
    const response = await this.api.put(
      `/default-warranty-document-merchant-info/${data.id}?name=${data.name}&address=${data.address}&phone=${data.phone}&fax=${data.fax}&warrantyConditions=${data.warrantyConditions}`,
      mutlipartData,
    );
    return response;
  }
}

export default new DocumentsService();
