import { Button, Tag } from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import paymentService from "@/services/paymentService";

import AmericanExpressIcon from "@/assets/icons/americanExpress.svg?react";
import DinersClubIcon from "@/assets/icons/dinersClub.svg?react";
import MasterCardIcon from "@/assets/icons/masterCard.svg?react";
import TroyIcon from "@/assets/icons/troy.svg?react";
import VisaIcon from "@/assets/icons/visa.svg?react";

const PaymentHero = ({ setActiveTab, merchantsPlan, fetchMerchantsPlan }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (loading) return <div>Loading...</div>;

  const onCancelPlan = () => {
    let cardId = merchantsPlan?.card.id;
    paymentService.cancelPayAsGo(cardId).then(() => {
      fetchMerchantsPlan();
    });
  };

  return (
    <div className="mx-auto my-16 flex max-w-4xl flex-col gap-6">
      <h1 className="mx-auto text-3xl font-semibold text-[#101828]">
        {t("payment.paymentHero.title")}
      </h1>
      <p className="mx-auto max-w-[768px] text-center text-base font-medium text-[#677389]">
        {t("payment.paymentHero.subtitle")}
      </p>
      <div className="rounded-lg border bg-white p-9 shadow-sm">
        {merchantsPlan ? (
          <PackageOverview
            setActiveTab={setActiveTab}
            merchantsPlan={merchantsPlan}
            onCancelPlan={onCancelPlan}
          />
        ) : (
          <DontHavePackage />
        )}
      </div>
    </div>
  );
};

export default PaymentHero;

const PackageOverview = ({ setActiveTab, merchantsPlan, onCancelPlan }) => {
  const { t } = useTranslation();

  const icon = {
    MASTER_CARD: <MasterCardIcon className="h-8 w-8" />,
    VISA: <VisaIcon className="h-8 w-8" />,
    AMEX: <AmericanExpressIcon className="h-8 w-8" />,
    TROY: <TroyIcon className="h-8 w-8" />,
    DINERS: <DinersClubIcon className="h-8 w-8" />,
  };

  return (
    <div className="flex flex-col items-center justify-between gap-8">
      <div className="flex w-full items-center justify-between">
        {merchantsPlan?.merchantPaymentPlan !== "PAY_AS_GO" && (
          <div className="flex items-center gap-4">
            <svg
              width="76"
              height="76"
              viewBox="0 0 76 76"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="6" y="6" width="64" height="64" rx="32" fill="#AAC2F7" />
              <rect
                x="6"
                y="6"
                width="64"
                height="64"
                rx="32"
                stroke="#CCDBFA"
                stroke-width="10.6667"
              />
              <path
                d="M40.0404 33.1358C39.2771 33.68 38.3431 34 37.3344 34C34.7571 34 32.6677 31.9107 32.6677 29.3334C32.6677 26.756 34.7571 24.6667 37.3344 24.6667C39.005 24.6667 40.4706 25.5446 41.2951 26.8642M30.0011 48.7829H33.4814C33.9352 48.7829 34.3862 48.8369 34.8261 48.9449L38.5036 49.8385C39.3015 50.0329 40.1328 50.0518 40.939 49.8952L45.005 49.1042C46.0791 48.8949 47.0672 48.3806 47.8415 47.6274L50.7182 44.829C51.5398 44.0312 51.5398 42.7366 50.7182 41.9374C49.9786 41.2179 48.8074 41.1369 47.9706 41.7471L44.6179 44.1932C44.1377 44.5441 43.5535 44.7331 42.9526 44.7331H39.7151L41.7758 44.733C42.9373 44.733 43.8782 43.8178 43.8782 42.6879V42.2789C43.8782 41.3407 43.2218 40.5226 42.2865 40.2958L39.1059 39.5223C38.5882 39.3968 38.0581 39.3334 37.5252 39.3334C36.2388 39.3334 33.9102 40.3984 33.9102 40.3984L30.0011 42.0332M48.6677 30.6667C48.6677 33.244 46.5784 35.3334 44.0011 35.3334C41.4237 35.3334 39.3344 33.244 39.3344 30.6667C39.3344 28.0894 41.4237 26 44.0011 26C46.5784 26 48.6677 28.0894 48.6677 30.6667ZM24.6677 41.4667L24.6677 49.2C24.6677 49.9468 24.6677 50.3201 24.813 50.6053C24.9409 50.8562 25.1449 51.0602 25.3957 51.188C25.681 51.3334 26.0543 51.3334 26.8011 51.3334H27.8677C28.6145 51.3334 28.9878 51.3334 29.273 51.188C29.5239 51.0602 29.7279 50.8562 29.8557 50.6053C30.0011 50.3201 30.0011 49.9468 30.0011 49.2V41.4667C30.0011 40.72 30.0011 40.3466 29.8557 40.0614C29.7279 39.8105 29.5239 39.6065 29.273 39.4787C28.9878 39.3334 28.6145 39.3334 27.8677 39.3334L26.8011 39.3334C26.0543 39.3334 25.681 39.3334 25.3957 39.4787C25.1449 39.6065 24.9409 39.8105 24.813 40.0614C24.6677 40.3466 24.6677 40.72 24.6677 41.4667Z"
                stroke="#0040C9"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="text-xl text-[#111729]">
              {merchantsPlan?.credit}
            </span>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-medium text-[#0040C9]">
            <span className="font-normal text-[#677389]">
              {t("payment.paymentHero.package", "Paket")}:
            </span>{" "}
            {merchantsPlan?.merchantPaymentPlan === "PAY_AS_GO"
              ? t("payment.paymentHero.payAsGo", "Kullandıkça Öde")
              : t("payment.paymentHero.counter", "Kontör")}
          </h3>
          {merchantsPlan?.merchantPaymentPlan === "PAY_AS_GO" && (
            <div className="flex flex-col gap-3">
              <h3 className="text-sm font-medium text-[#364153]">
                <span className="font-normal text-[#677389]">
                  {t("payment.paymentHero.startDate", "Başlangıç Tarihi")}:
                </span>{" "}
                {merchantsPlan?.planStartDate}
              </h3>
              <h3 className="text-sm font-medium text-[#364153]">
                <span className="font-normal text-[#677389]">
                  {t(
                    "payment.paymentHero.lastInvoiceDate",
                    "Son Fatura Tarihi",
                  )}
                  :
                </span>{" "}
                {merchantsPlan?.lastReceiveDate}
              </h3>
              <Tag
                size="sm"
                variant={
                  merchantsPlan?.isPaymentReceived == true
                    ? "green"
                    : merchantsPlan?.isPaymentReceived == false
                      ? "red"
                      : "yellow"
                }
              >
                {merchantsPlan?.isPaymentReceived == true
                  ? t("payment.paymentHero.paid", "Ödendi")
                  : merchantsPlan?.isPaymentReceived == false
                    ? t("payment.paymentHero.notPaid", "Ödenmedi")
                    : t(
                        "payment.paymentHero.paymentNotReceived",
                        "Ödeme henüz alınmadı",
                      )}
              </Tag>
              <button
                onClick={onCancelPlan}
                className="text-xs font-medium text-[#e6000060]"
              >
                {t("payment.paymentHero.cancelPlan", "Planı İptal Et")}
              </button>
            </div>
          )}
        </div>
        {merchantsPlan?.card && (
          <div className="flex w-full max-w-60 flex-col gap-6 rounded-lg border border-[#0049E6] bg-[#F9FAFC] p-3">
            <span className="flex w-full items-center justify-between text-[#0049E6]">
              {merchantsPlan?.card?.cardAlias}
              {icon[merchantsPlan?.card?.cardAssociation]}
            </span>
            <span>**** **** **** {merchantsPlan?.card.lastFourDigits}</span>
          </div>
        )}
      </div>
      <Button
        onClick={() => {
          setActiveTab(3);
          window.scrollTo(0, document.body.scrollHeight - 1400);
        }}
        variant="secondaryColor"
        className="w-full"
      >
        {t("payment.paymentHero.goToPaymentHistory")}
      </Button>
    </div>
  );
};

const DontHavePackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-between gap-8">
      <div className="w-full">
        <h2 className="text-lg font-semibold leading-6 text-[#101828]">
          {t(
            "payment.paymentHero.noPackage",
            "Paket: Henüz tanımlı paket bulunmamaktadır.",
          )}
        </h2>
        <p className="text-[#4A5567]">
          {t(
            "payment.paymentHero.updatePaymentInfo",
            "Garanti gönderebilmek için ödeme bilgilerinizi güncelleyin.",
          )}
        </p>
      </div>
      <Button
        onClick={() =>
          navigate(
            "/settings/payment/add-credit-card?redirect=/settings/payment&method=PAY_AS_GO",
          )
        }
        variant="secondaryGray"
        className="w-full"
      >
        {t("payment.paymentHero.buttonText")}
      </Button>
    </div>
  );
};
