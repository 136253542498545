import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

const AddNewDocumentForm = ({ control }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormField
        control={control}
        name="documentName"
        render={({ field }) => (
          <FormItem>
            <Label>{t("settingsPage.documentName")} *</Label>
            <FormControl>
              <Input maxLength={50} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="documentFile"
        render={({ field }) => (
          <FormItem>
            <Label>{t("settingsPage.document")} *</Label>
            <FormControl>
              <Input
                accept=".pdf, image/*"
                type="file"
                onChange={(e) => {
                  field.onChange(e.target.files ? e.target.files[0] : null);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

export default AddNewDocumentForm;
