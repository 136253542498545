import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";
import { RadioGroup, RadioGroupItem } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import setupClaimsService from "@/services/setupClaimsService";

import ConfirmAppointment from "@/components/claims/SetupClaims/confirmAppointment";
import RejectAppointment from "@/components/claims/SetupClaims/rejectAppointment";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ClaimEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [data, setData] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const [pickedDate, setPickedDate] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const fetchData = () => {
    setupClaimsService.getClaim(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    fetchData();

    setupClaimsService.getAvailableDates(id).then((res) => {
      setAvailableDates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/setup">
          {t("claimsPage.setupRequests")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.setupDetail")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.setupClaim")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.productName")}</Label>
            <Input value={data?.individualCustomerProductName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.claimNumber")}</Label>
            <Input value={data?.id} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.customerName")}</Label>
            <Input
              value={
                data?.individualCustomerFirstName +
                " " +
                data?.individualCustomerLastName
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.phone")}</Label>
            <Input value={data?.phone} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.address")}</Label>
            <Textarea value={data?.address?.addressLine} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.customerNote")}</Label>
            <Textarea
              value={data?.customerNote}
              disabled
              className="col-span-2"
            />
          </div>
        </div>
      </div>
      {data.status !== "APPROVED" && data.status !== "REJECTED" ? (
        <>
          <div className="container mx-auto mb-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox">
              <div className="flex w-max items-center justify-between gap-2">
                <h2 className="text-lg font-medium">
                  {t("claimsPage.setupClaim")}
                </h2>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                          stroke="#677389"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("claimsPage.tooltipMsg")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <RadioGroup
                className="py-5"
                onValueChange={(value) => {
                  setPickedDate(value);
                }}
                defaultValue={pickedDate}
              >
                {availableDates?.map((date, index) => (
                  <div key={index} className="mb-5 flex items-center space-x-2">
                    <RadioGroupItem
                      className="mr-2"
                      value={date}
                      id={`date${index}`}
                    />
                    <Label className="text-sm" htmlFor={`date${index}`}>
                      {date.setupDate} {" / "}
                      {date.partOfDay === "MORNING"
                        ? "08.00 - 12.00"
                        : date.partOfDay === "AFTERNOON"
                          ? "12.00 - 16.00"
                          : "16.00 - 20.00"}
                    </Label>
                  </div>
                ))}
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={""} id="none" />
                  <Label htmlFor="none">{t("claimsPage.none")}</Label>
                </div>
              </RadioGroup>
              <Label className="col-span-2">
                {t("claimsPage.merchantNote")}
              </Label>
              <Textarea
                value={data?.merchantNote || ""}
                onChange={(e) =>
                  setData({ ...data, merchantNote: e.target.value })
                }
                className="col-span-2"
              ></Textarea>
              <div className="ml-auto mt-9 flex w-max gap-2">
                <Button
                  disabled={pickedDate !== "" ? true : false}
                  variant="secondaryGray"
                  onClick={() => setOpenReject(true)}
                >
                  {t("claimsPage.reject")}
                </Button>
                <Button
                  disabled={pickedDate !== "" ? false : true}
                  onClick={() => setOpenConfirm(true)}
                >
                  {t("claimsPage.createAppointment")}
                </Button>
              </div>
            </div>
          </div>
          <RejectAppointment
            open={openReject}
            onOpenChange={setOpenReject}
            data={data}
          />
          <ConfirmAppointment
            open={openConfirm}
            onOpenChange={setOpenConfirm}
            appointmentInfo={{ pickedDate, data }}
            reFetchData={fetchData}
          />
        </>
      ) : (
        <>
          <div className="container mx-auto mb-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox flex flex-col gap-4">
              <h2 className="text-lg font-medium">
                {t("claimsPage.setupClaim")}
              </h2>
              {data.status === "APPROVED" ? (
                <>
                  <Label className="col-span-2">
                    {t("claimsPage.setupDate")}
                  </Label>
                  <Input value={data?.setupDate} disabled />
                  <Label className="col-span-2">
                    {t("claimsPage.merchantNote")}
                  </Label>
                  <Textarea
                    disabled
                    value={data?.merchantNote || ""}
                    className="col-span-2"
                  ></Textarea>
                </>
              ) : (
                <Tag variant="red">{t("claimsPage.rejected")}</Tag>
              )}
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default ClaimEditPage;
