import {
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import serviceClaimsService from "@/services/serviceClaimsService";

import Messages from "@/components/claims/ServiceClaims/messages";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

const ClaimEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();

  const [data, setData] = useState({});

  const getData = () => {
    serviceClaimsService.getClaim(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStatus = (e) => {
    setData({ ...data, status: e });
    serviceClaimsService
      .updateClaim(id, {
        status: e,
      })
      .then(() => {
        toast({
          title: t("claimsPage.requestChangedSuccessfully"),
          variant: "success",
        });
        getData();
      })
      .catch(() => {
        toast({
          title: t("claimsPage.requestChangedError"),
          variant: "error",
        });
      });
  };

  const status = [
    "WAITING_MERCHANT",
    "WAITING_CUSTOMER",
    "REJECTED",
    "APPROVED",
    "IN_CARGO",
    "DAMAGE_ASSESSMENT_PHASE",
  ];

  const getClaimStatus = (status) => {
    switch (status) {
      case "WAITING_MERCHANT":
        return t("claimsPage.waitingMerchant");
      case "WAITING_CUSTOMER":
        return t("claimsPage.waitingCustomer");
      case "REJECTED":
        return t("claimsPage.rejected");
      case "APPROVED":
        return t("claimsPage.approved");
      case "IN_CARGO":
        return t("claimsPage.inCargo");
      case "DAMAGE_ASSESSMENT_PHASE":
        return t("claimsPage.damageAssessmentPhase");
      case "DELIVERED":
        return t("claimsPage.delivered");
      default:
        return "";
    }
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service">
          {t("claimsPage.serviceRequests")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.claimDetails")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.serviceClaim")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.claimNumber")}</Label>
            <Input value={data?.id} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.customerName")}</Label>
            <Input
              value={
                data?.individualCustomerFirstName +
                " " +
                data?.individualCustomerLastName
              }
              disabled
            />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.productName")}</Label>
            <Input value={data?.individualCustomerProductName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("claimsPage.phone")}</Label>
            <Input value={data?.phone} disabled />
          </div>
          <div className="col-span-2 flex flex-col gap-4">
            <Label className="col-span-2">
              {t("claimsPage.productProblem")}
            </Label>
            <Textarea value={data?.description} disabled />
          </div>
        </div>
        <div className="formBox flex w-full justify-start gap-5">
          <div className="flex flex-col gap-4">
            <Label>{t("claimsPage.status")}</Label>
            <Select
              onValueChange={(e) => onChangeStatus(e)}
              value={data?.status}
            >
              <SelectTrigger>
                <SelectValue></SelectValue>
              </SelectTrigger>
              <SelectContent>
                {status.map((item) => (
                  <SelectItem key={item} value={item}>
                    {getClaimStatus(item)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
      <Messages status={data?.status} claimId={id} />
    </main>
  );
};

export default ClaimEditPage;
