import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Textarea } from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import serviceRecordsService from "@/services/serviceRecordsService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import SelectProduct from "@/components/shared/selectProduct";

import { format, isValid, parse } from "date-fns";
import { useHookFormMask } from "use-mask-input";
import * as z from "zod";

const ClaimCreatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const schema = z.object({
    firstName: z.string({ message: t("claimsPage.firstNameNonEmpty") }),
    lastName: z.string({ message: t("claimsPage.lastNameNonEmpty") }),
    phone: z.string({ message: t("claimsPage.phoneNonEmpty") }),
    countryCode: z.string({ message: t("claimsPage.countryNonEmpty") }),
    merchantProductId: z.number({ message: t("claimsPage.productIdNonEmpty") }),
    reason: z.string({ message: t("claimsPage.reasonNonEmpty") }),
    purchaseDate: z
      .string()
      .min(1, "Required")
      .refine((date) => {
        const parsed = parse(date, "dd/MM/yyyy", new Date());
        return isValid(parsed);
      }, t("common.invalidDate")),
    state: z.string({ message: t("claimsPage.statusNonEmpty") }),
    note: z.string({ message: t("claimsPage.noteNonEmpty") }),
    processDate: z
      .string()
      .min(1, "Required")
      .refine((date) => {
        const parsed = parse(date, "dd/MM/yyyy", new Date());
        return isValid(parsed);
      }, t("common.invalidDate")),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "TR",
      merchantProductId: null,
      reason: "FAULT",
      purchaseDate: "",
      state: "GUARANTEED",
      note: "",
      processDate: "",
    },
  });

  const registerWithMask = useHookFormMask(form.register);

  const transformDateToBackend = (dateStr) => {
    if (!dateStr) return null;
    const parsedDate = parse(dateStr, "dd/MM/yyyy", new Date());
    return isValid(parsedDate) ? format(parsedDate, "yyyy-MM-dd") : null;
  };

  const onSubmit = (data) => {
    setLoading(true);

    const transformedData = {
      ...data,
      purchaseDate: transformDateToBackend(data.purchaseDate),
      processDate: transformDateToBackend(data.processDate),
    };

    serviceRecordsService
      .createServiceRecord(transformedData)
      .then(() => {
        toast({
          title: t("claimsPage.serviceRecordCreatedSuccessfully"),
          variant: "success",
        });
        navigate("/claims/service-record");
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.errors[0]?.message,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service-record">
          {t("claimsPage.serviceRecords")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("claimsPage.serviceRecordDetail")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("claimsPage.serviceRecordDetails")}
          </h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="col-span-2 grid grid-cols-2 gap-4"
            >
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.firstName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("common.lastName")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-4">
                <FormField
                  control={form.control}
                  name="countryCode"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("common.country")}</Label>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Ülke" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="TR">+90</SelectItem>
                          <SelectItem value="US">+1</SelectItem>
                          <SelectItem value="UZ">+998</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <Label>{t("claimsPage.phone")}</Label>
                      <FormControl>
                        <Input type="tel" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="merchantProductId"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("products.productName")}</Label>
                    <FormControl>
                      <SelectProduct
                        value={field.value}
                        setValue={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="reason"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.reason")}</Label>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <SelectTrigger>
                          <SelectValue placeholder={t("claimsPage.fault")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="FAULT">
                            {t("claimsPage.fault")}
                          </SelectItem>
                          <SelectItem value="INSTALLATION">
                            {t("claimsPage.setup")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="purchaseDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("claimsPage.purchaseDate")}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        {...field}
                        {...registerWithMask("purchaseDate", {
                          mask: "99/99/9999",
                          placeholder: "DD/MM/YYYY",
                        })}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="processDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <Label>{t("claimsPage.processDate")}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        {...field}
                        {...registerWithMask("processDate", {
                          mask: "99/99/9999",
                          placeholder: "DD/MM/YYYY",
                        })}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.status")}</Label>
                    <FormControl>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="GUARANTEED">
                            {t("claimsPage.guaranteed")}
                          </SelectItem>
                          <SelectItem value="NOT_GUARANTEED">
                            {t("claimsPage.notGuaranteed")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="note"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.note")}</Label>
                    <FormControl>
                      <Textarea maxLength={500} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <div className="flex w-full justify-end">
          <Button disabled={loading} onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default ClaimCreatePage;
