import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import documentsService from "@/services/documentsService";

import useDocumentsStore from "@/stores/useDocumentsStore";

import DefaultDocumentCard from "@/components/documents/defaultDocumentCard";
import FilterSheet from "@/components/documents/filterSheet";
import FilterTags from "@/components/documents/filterTags";
import EmptyTable from "@/components/shared/EmptyTable";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import FilterIcon from "@/assets/icons/filter.svg?react";
import TrashIcon from "@/assets/icons/trash.svg?react";

const Documents = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const { documents, loading, filters, getDocuments, setFilters } =
    useDocumentsStore();

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getDocuments();
  };

  const onClickItem = (e, id) => {
    e.stopPropagation();
    navigate(`/settings/documents/${id}`);
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();

    documentsService
      .deleteDocument(id)
      .then(() => {
        toast({
          title: t("settingsPage.documentDeletedSuccessfully"),
          variant: "success",
        });
        // set timeout becaıse api is not fast enough
        setTimeout(() => {
          getDocuments();
        }, 500);
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.errors[0]?.message,
          variant: "error",
        });
      });
  };

  const parseType = (type) => {
    switch (type) {
      case "USER_MANUAL_DOCUMENT":
        return t("settingsPage.userManual");
      case "WARRANTY_DOCUMENT":
        return t("settingsPage.warrantyDocument");
      default:
        return "";
    }
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end pb-4">
        <Button onClick={() => navigate("/settings/documents/create")}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6.66667V13.3333M6.66667 10H13.3333M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{t("settingsPage.addDocument")}</span>
        </Button>
      </div>
      <div className="rounded-lg">
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-between gap-5">
            <FilterTags />
            <Button
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
              className="min-w-[150px] font-medium"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={3} />
            </Table>
          ) : (
            <main className="flex flex-col">
              <h2 className="px-8 py-6 text-xl font-medium text-[#111729]">
                {t("titles.documents")}:{" "}
              </h2>
              <div className="grid grid-cols-3 gap-6 px-8">
                <DefaultDocumentCard />
                {documents.length > 0 ? (
                  documents.map((document) => (
                    <div
                      key={document.id}
                      onClick={(e) => onClickItem(e, document.id)}
                      className="relative flex cursor-pointer flex-col rounded-lg border border-[#EAECF0] bg-[#F9FAFC] p-6 pt-12"
                    >
                      <Tag
                        size="sm"
                        variant={
                          document.type === "USER_MANUAL_DOCUMENT"
                            ? "yellow"
                            : "green"
                        }
                        className="absolute right-2 top-2"
                      >
                        {parseType(document.type)}
                      </Tag>

                      <svg
                        width="30"
                        height="36"
                        viewBox="0 0 30 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.3332 1.78247V8.66671C18.3332 9.60013 18.3332 10.0668 18.5148 10.4234C18.6746 10.737 18.9296 10.9919 19.2432 11.1517C19.5997 11.3334 20.0664 11.3334 20.9998 11.3334H27.8841M18.3332 26.3333H8.33317M21.6665 19.6666H8.33317M28.3332 14.647V26.6666C28.3332 29.4668 28.3332 30.867 27.7882 31.9365C27.3088 32.8773 26.5439 33.6423 25.6031 34.1216C24.5336 34.6666 23.1334 34.6666 20.3332 34.6666H9.6665C6.86624 34.6666 5.46611 34.6666 4.39655 34.1216C3.45574 33.6423 2.69084 32.8773 2.21147 31.9365C1.6665 30.867 1.6665 29.4668 1.6665 26.6666V9.33325C1.6665 6.53299 1.6665 5.13286 2.21147 4.0633C2.69084 3.12249 3.45574 2.35759 4.39655 1.87822C5.46611 1.33325 6.86624 1.33325 9.6665 1.33325H15.0195C16.2424 1.33325 16.8539 1.33325 17.4293 1.4714C17.9395 1.59389 18.4272 1.79591 18.8746 2.07005C19.3792 2.37926 19.8116 2.81164 20.6763 3.6764L25.99 8.99011C26.8548 9.85486 27.2872 10.2872 27.5964 10.7918C27.8705 11.2392 28.0725 11.7269 28.195 12.2371C28.3332 12.8125 28.3332 13.424 28.3332 14.647Z"
                          stroke="#111729"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div className="mt-4 flex items-center justify-between">
                        <span className="text-lg font-medium text-[#111729]">
                          {document.name}
                        </span>
                      </div>
                      <span className="text-sm text-[#4A5567]">
                        {/* <b className="mr-1 text-[#111729]">123</b>ürün bağlı */}
                      </span>
                      <div className="flex items-center justify-between gap-2 pt-10">
                        <span className="text-sm font-semibold text-[#111729]">
                          <span className="font-normal text-[#4A5567]">
                            {t("common.creationDate", "Oluşturulma Tarihi")}:{" "}
                          </span>
                          {document.createdAt}
                        </span>
                        <button
                          onClick={(e) => onClickDelete(e, document.id)}
                          variant="secondaryGray"
                          className="rounded-lg p-2"
                        >
                          <TrashIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyTable />
                )}
              </div>
            </main>
          )}
          {filters.totalPages > 1 && (
            <Pagination
              totalPages={filters.totalPages}
              currentPage={filters.page}
              pageSize={filters.size}
              onPageChange={(page) => onPageChange(page)}
            />
          )}
        </div>
      </div>
      <FilterSheet open={openFilterSheet} onOpenChange={setOpenFilterSheet} />
    </main>
  );
};

export default Documents;
