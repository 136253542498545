import ExtendedWarrantyService from "@/services/extendedWarrantyService";

import { create } from "zustand";

const useExtendedWarrantyStore = create((set) => ({
  loading: false,
  extendedWarranties: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    direction: "ASC",
  },
  getExtendedWarranties: async () => {
    set({ loading: true });
    const data = await ExtendedWarrantyService.getExtendedWarramties(
      useExtendedWarrantyStore.getState().filters,
    );
    set({
      extendedWarranties: data.content,
      loading: false,
      filters: {
        ...useExtendedWarrantyStore.getState().filters,
        totalPages: data.totalPages,
        page: data.page,
      },
    });
  },
  setFilters: (filters) => {
    set({ filters });
  },
}));

export default useExtendedWarrantyStore;
