/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import userService from "@/services/userService";

import { cn } from "@/lib/utils";

const AdminTab = ({ user, getData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({
    merchantUserId: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
  });

  const profileFilds = [
    { label: t("common.firstName"), value: user?.firstName },
    { label: t("common.lastName"), value: user?.lastName },
    { label: t("common.email"), value: user?.email, link: true },
    { label: t("common.phone"), value: user?.phone, link: true },
  ];

  useEffect(() => {
    setNewData({
      merchantUserId: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
    });
  }, [user]);

  const onSave = () => {
    setLoading(true);
    userService
      .updateUser(newData)
      .then(() => {
        getData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="mb-2 flex max-w-[800px] items-center justify-between pb-3 pt-5">
        <h2 className="font-medium text-[#97a2b6]">
          {t("settingsPage.profileInfo")}
        </h2>
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="secondaryColor">{t("settingsPage.edit")}</Button>
          </SheetTrigger>
          <SheetContent className="m-4 rounded-lg bg-white p-4">
            <SheetHeader>
              <SheetTitle>{t("settingsPage.edit")}</SheetTitle>
            </SheetHeader>
            <div className="mt-4 flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Label>{t("common.firstName")}</Label>
                <Input
                  value={newData?.firstName}
                  onChange={(e) =>
                    setNewData({ ...newData, firstName: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label>{t("common.lastName")}</Label>
                <Input
                  value={newData?.lastName}
                  onChange={(e) =>
                    setNewData({ ...newData, lastName: e.target.value })
                  }
                />
              </div>
            </div>
            <SheetFooter className="mt-4">
              <SheetClose asChild>
                <Button
                  disabled={loading || !newData.firstName || !newData.lastName}
                  onClick={onSave}
                >
                  {t("common.save")}
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </div>
      <div className="flex max-w-[800px] flex-col gap-4 rounded-lg border border-[#F2F5F9] bg-white p-6 shadow-sm">
        {profileFilds.map((item, index) => (
          <div
            key={index}
            className="flex flex-col gap-2 border-b pb-4 last:border-0 last:pb-0"
          >
            <label className="text-sm font-medium text-[#97a2b6]">
              {item.label}
            </label>
            <span
              className={cn(
                "text-sm font-semibold text-[#2b2b2b]",
                item.link && "text-[#0049E6] underline",
              )}
            >
              {item.value}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminTab;
