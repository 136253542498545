import i18n from "@/i18n";
import axios from "@/lib/axios";

class SellerService {
  constructor() {
    this.api = axios;
    this.partnerApi = axios.create({
      baseURL: "https://test-gw-partner.egaranti.dev",
      headers: {
        "Accept-Language": i18n.language,
      },
    });
  }

  async getSellers(params) {
    const response = await this.api.get("/sellers", { params });

    return response.data;
  }

  async getSeller(id) {
    const response = await this.api.get(`/sellers/${id}`);

    return response.data;
  }

  async getSellersWaiting(params) {
    const response = await this.api.get("/sellers/waiting", { params });

    return response.data;
  }

  async updateWaitingSeller(id, body) {
    const response = await this.api.put(`/sellers/${id}/status`, body);

    return response.data;
  }

  async exportSellers(params) {
    const response = await this.api.get("/sellers/export", { params });

    return response.data;
  }

  async getMerchantCodes(params) {
    // merchants?page=1&size=10
    const response = await this.partnerApi.get("/merchants", { params });

    return response.data;
  }

  async postSeller(body) {
    const response = await this.partnerApi.post("/temporary-sellers", body);

    return response.data;
  }

  async verifySeller(smsId, otp) {
    const response = await this.partnerApi.post(`/temporary-sellers/verify`, {
      temporarySellerDataId: smsId,
      sixDigitCode: otp,
    });

    return response.data;
  }
}

export default new SellerService();
